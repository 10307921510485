<script>
  import filesize from 'filesize';
  import { _, date, locale } from 'svelte-i18n'
  import Button from './Button.svelte'

  export let name;
  export let coverImage;
  export let fileType;
  export let fileSize;
  export let fileUrl;
  export let createdAt;
  export let updatedAt;

  const [ lang, loc ] = $locale.split('-');
  const localisedName = lang in name ? name[lang] : name['en'];
  const modificationDate = Date.parse(updatedAt || createdAt);

  const latestCoverImage = `${coverImage}?${modificationDate / 1000}`;
  const latestFileUrl = `${fileUrl}?${modificationDate / 1000}`;

  const formattedSize = (fileSize === undefined || fileSize === null)
    ? $_('unknown-date')
    : filesize(fileSize);
  const formattedDate = Number.isNaN(modificationDate)
    ? $_('unknown-date')
    : $date(modificationDate, { format: 'medium' });
</script>

<div class="card">
  <div class="cover">
    <img class="cover-image" src={latestCoverImage} alt={localisedName}>
  </div>

  <h2 class="name">{localisedName}</h2>

  <p class="size">
    <img src="./images/icon-pdf.svg" alt="Clock" width="32" height="32">
    {formattedSize}
  </p>
  <p class="time">
    <img src="./images/icon-clock.svg" alt="Clock" width="32" height="32">
    {formattedDate}
  </p>

  <Button type="link" attributes={{
    href: latestFileUrl,
    download: 'download',
    target: '_blank',
    type: fileType
  }}>
    {$_('button')}
  </Button>
</div>

<style>
  .card {
    display: grid;
    align-content: space-between;

    padding: 1.25rem;
    background: #fff;
    box-shadow: 0 1.25rem 3.125rem 0 rgba(0,37,67,0.15);
    border-radius: 1.25rem;
    overflow: hidden;
  }

  .cover {
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative; /* If you want text inside of it */
    border-radius: 1rem;
    overflow: hidden;
  }

  .cover-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .name {
    font-weight: 500;
    font-size: 1.3125rem;
    letter-spacing: 0;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  p {
    display: grid;
    grid-template-columns: 32px 1fr;
    align-items: center;
    margin: 0;
    line-height: 32px;
  }

  .size {
    font-size: 14px;
    letter-spacing: 0;
  }

  .time {
    font-size: 12px;
    letter-spacing: 0;
    margin-bottom: 15px;
  }
</style>
