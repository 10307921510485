import { addMessages, init, getLocaleFromNavigator } from 'svelte-i18n';

import de from './messages/de.json';
import en from './messages/en.json';
import es from './messages/es.json';
import fr from './messages/fr.json';
import it from './messages/it.json';

addMessages('de', de);
addMessages('en', en);
addMessages('es', es);
addMessages('fr', fr);
addMessages('it', it);

init({
  fallbackLocale: 'en',
  initialLocale: getLocaleFromNavigator(),
});
