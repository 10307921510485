export const documents = [
  {
    slug: 'welcome-brochure',
    name: {
      de: 'Willkommensbroschüre',
      en: 'Welcome brochure',
      es: 'Folleto de bienvenida',
      fr: 'Brochure de bienvenue',
      it: 'Brochure di benvenuto',
    },
    coverImage:
      'https://storage.googleapis.com/bz-digital-kiosk.appspot.com/documents/cover/welcome-brochure.jpg',
    fileType: 'application/pdf',
    fileSize: '1737050',
    fileUrl:
      'https://storage.googleapis.com/bz-digital-kiosk.appspot.com/documents/welcome-brochure.pdf',
    createdAt: '2020-07-22T16:55:00',
    updatedAt: '2020-07-22T16:55:00',
  },
  {
    slug: 'covid19-measures',
    name: {
      de: 'COVID-19 Maßnahmen',
      en: 'COVID-19 Measures',
      es: 'Medidas COVID-19',
      fr: 'Mesures COVID-19',
      it: 'Misure COVID-19',
    },
    coverImage:
      'https://storage.googleapis.com/bz-digital-kiosk.appspot.com/documents/cover/covid19-measures.jpg',
    fileType: 'application/pdf',
    fileSize: '1138153',
    fileUrl:
      'https://storage.googleapis.com/bz-digital-kiosk.appspot.com/documents/covid19-measures.pdf',
    createdAt: '2020-07-10T12:10:00',
    updatedAt: '2021-03-26T19:00:00',
  },
  {
    slug: 'themed-dinner',
    name: {
      de: 'Themenabendessen',
      en: 'Themed dinner',
      es: 'Cena temática',
      fr: 'Dîner à thème',
      it: 'Cena a tema',
    },
    coverImage:
      'https://storage.googleapis.com/bz-digital-kiosk.appspot.com/documents/cover/themed-dinner.png',
    fileType: 'application/pdf',
    fileSize: '12740000',
    fileUrl:
      'https://storage.googleapis.com/bz-digital-kiosk.appspot.com/documents/themed-dinner.pdf',
    createdAt: '2021-04-27T09:00:00',
    updatedAt: '2021-04-27T09:00:00',
  },
  {
    slug: 'spa-carte-2020',
    name: {
      de: 'Angebot an Spa-Anwendungen',
      en: 'Spa Carte',
      es: 'Carta del Spa',
      fr: 'Carte du Spa',
      it: 'Trattamenti nella Spa',
    },
    coverImage:
      'https://storage.googleapis.com/bz-digital-kiosk.appspot.com/documents/cover/spa-carte-2021.jpg',
    fileType: 'application/pdf',
    fileSize: '368020',
    fileUrl:
      'https://storage.googleapis.com/bz-digital-kiosk.appspot.com/documents/spa-carte-2021.pdf',
    createdAt: '2020-01-23T13:13:00',
    updatedAt: '2021-01-25T00:00:00',
  },
  {
    slug: 'wines',
    name: {
      de: 'Weinkarte',
      en: 'Wines list',
      es: 'Carta de vinos',
      fr: 'Carte des vins',
      it: 'Lista dei vini',
    },
    coverImage:
      'https://storage.googleapis.com/bz-digital-kiosk.appspot.com/documents/cover/wines.png',
    fileType: 'application/pdf',
    fileSize: '3805913',
    fileUrl:
      'https://storage.googleapis.com/bz-digital-kiosk.appspot.com/documents/wines.pdf',
    createdAt: '2021-10-27T13:02:00',
    updatedAt: '2021-10-27T13:02:00',
  },
  {
    slug: 'fuerteventura-beaches',
    name: {
      de: 'Fuerteventura Strände',
      en: 'Fuerteventura Beaches',
      es: 'Playas de Fuerteventura',
      fr: 'Plages de Fuerteventura',
      it: 'Spiagge di Fuerteventura',
    },
    coverImage:
      'https://storage.googleapis.com/bz-digital-kiosk.appspot.com/documents/cover/fuerteventura-beaches.png',
    fileType: 'application/pdf',
    fileSize: '12740000',
    fileUrl:
      'https://storage.googleapis.com/bz-digital-kiosk.appspot.com/documents/fuerteventura-beaches.pdf',
    createdAt: '2021-04-26T09:00:00',
    updatedAt: '2021-04-26T09:00:00',
  },
  {
    slug: 'restaurant-list',
    name: {
      de: 'Liste der Restaurants',
      en: 'List of restaurants',
      es: 'Lista de restaurantes',
      fr: 'Liste des restos',
      it: 'Elenco dei ristoranti',
    },
    coverImage:
      'https://storage.googleapis.com/bz-digital-kiosk.appspot.com/documents/cover/restaurant-list.png',
    fileType: 'application/pdf',
    fileSize: '523000',
    fileUrl:
      'https://storage.googleapis.com/bz-digital-kiosk.appspot.com/documents/restaurant-list.pdf',
    createdAt: '2021-05-22T22:00:00',
    updatedAt: '2021-05-22T22:00:00',
  },
  {
    slug: '10-hotspots',
    name: {
      de: '10 hotspots',
      en: '10 hotspots',
      es: '10 puntos calientes',
      fr: '10 points chauds',
      it: '10 hotspot',
    },
    coverImage:
      'https://storage.googleapis.com/bz-digital-kiosk.appspot.com/documents/cover/10-hotspots.png',
    fileType: 'application/pdf',
    fileSize: '724100',
    fileUrl:
      'https://storage.googleapis.com/bz-digital-kiosk.appspot.com/documents/10-hotspots.pdf',
    createdAt: '2021-05-22T22:00:00',
    updatedAt: '2021-05-22T22:00:00',
  },
  {
    slug: 'covid19-coverage',
    name: {
      de: 'COVID-19-Abdeckung garantiert',
      en: 'COVID-19 Coverage guaranteed',
      es: 'Cobertura COVID-19 garantizada',
      fr: 'Couverture COVID-19 garantie',
      it: 'Copertura COVID-19 garantita',
    },
    coverImage:
      'https://storage.googleapis.com/bz-digital-kiosk.appspot.com/documents/cover/covid19-coverage.png',
    fileType: 'application/pdf',
    fileSize: '4820252',
    fileUrl:
      'https://storage.googleapis.com/bz-digital-kiosk.appspot.com/documents/covid19-coverage.pdf',
    createdAt: '2020-09-14T09:00:00',
    updatedAt: '2020-09-29T13:00:00',
  },
  {
    slug: 'covid19-terms',
    name: {
      de: 'COVID-19 Unterkunftsbedingungen',
      en: 'COVID-19 lodging terms',
      es: 'Condiciones alojamiento COVID-19',
      fr: 'Conditions de logement COVID-19',
      it: 'Condizioni abitative COVID-19',
    },
    coverImage:
      'https://storage.googleapis.com/bz-digital-kiosk.appspot.com/documents/cover/covid19-terms.png',
    fileType: 'application/pdf',
    fileSize: '535727',
    fileUrl:
      'https://storage.googleapis.com/bz-digital-kiosk.appspot.com/documents/covid19-terms.pdf',
    createdAt: '2020-11-03T09:00:00',
    updatedAt: '2020-11-03T09:00:00',
  },
];
