<script>
  export let type = 'link';
  export let attributes = {};

  let {href, ...attrs} = attributes;
</script>

{#if type === 'link'}
  <a class="button" {href} {...attrs}>
    <slot />
  </a>
{:else}
  <button class="button" {...attrs}>
    <slot />
  </button>
{/if}

<style>
  .button {
    font-size: 12px;
    color: #fff;
    letter-spacing: 2px;
    text-align: center;
    display: grid;
    place-content: center;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    height: 36px;
    border-radius: 18px;
    background-image: linear-gradient(45deg, #2083d5 0%, #6cc0eb 100%);
  }
</style>
