<script>
  import { _ } from 'svelte-i18n';
  import Card from './components/Card.svelte'
  import { documents } from '../public/data';
</script>

<header>
  <div class="header__wrap">
    <div class="header__aux header__aux--prepend">
      <img src="./images/logo.svg" class="logo" alt="Logo" width="40" height="40">
    </div>
    <div class="header__content">
      <h1>{$_('title')}</h1>
    </div>
    <div class="header__aux header__aux--append"></div>
  </div>
</header>

<main>
  <div class="main__wrap">
    <div class="list">
    {#each documents as d}
      <Card {...d} />
    {:else}
      <p class="no">{$_('no-documents')}</p>
    {/each}
    </div>
  </div>
</main>

<style>
  header, main {
    display: grid;
    place-items: start center;
  }

  header {
    position: sticky;
    top: 0;
    z-index: 9999;

    height: 3.75rem;

    background-color: #2083d5;
    color: white;
  }

  .header__wrap,
  .main__wrap {
    display: grid;
    width: 100vw;
  }

  .header__wrap {
    grid-template-columns: 60px 1fr 60px;
  }

  .header__aux {
    width: 60px;
    height: 60px;
  }

  .logo {
    margin: 10px;
  }

  h1 {
    margin: 0;
    line-height: 3.75rem;
    text-align: center;
    font-weight: normal;
    font-size: 1.25rem;
  }

  @media (min-width: 62rem) {
    .header__wrap,
    .main__wrap {
      width: 60rem;
    }
  }

  .list {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 1.875rem;
  padding: 1.875rem;
}

@media (min-width: 36rem) {
  .list {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1.875rem;
  }
}

@media (min-width: 62rem) {
  .list {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1.875rem;
  }
}
</style>
